import { render, staticRenderFns } from "./AlertsList.vue?vue&type=template&id=6c571244&scoped=true"
import script from "./AlertsList.vue?vue&type=script&lang=js"
export * from "./AlertsList.vue?vue&type=script&lang=js"
import style0 from "./AlertsList.vue?vue&type=style&index=0&id=6c571244&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c571244",
  null
  
)

export default component.exports